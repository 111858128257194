import axios from "axios";
import API_END_POINT from "config/APIEndpoints";

const BASE_API_URL = API_END_POINT;
const ADMIN_API_URL = BASE_API_URL + "/api/v1";
const FYI_URL = BASE_API_URL + "/api/v1" + "/app/fyi";

// Util
export const getHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
};

export const getMultipartHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "multipart/form-data",
    },
  };
};

export const instance = axios.create({
  baseURL: ADMIN_API_URL,
});

export const fyiInstance = axios.create({
  baseURL: FYI_URL,
});

export const refreshToken = () =>
  instance.get("admin/dashboard/token", getHeader());
