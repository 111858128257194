import { useState } from "react";

import EditDetails from "./EditDetails";
import ViewDetails from "./ViewDetails";
import StoryPreviewModal from "../StoryPreview/Modal";
import WideModalsWrapper from "components/Common/Modals/ModalsWrapper/WideModalWrapper";

function DeckDetails({ details, setDetails, onUpdate, setUpdatingStory }) {
  const [isEditable, setEditable] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  return (
    <div className="space-y-6">
      {isEditable ? (
        <EditDetails
          setUpdatingStory={setUpdatingStory}
          onUpdate={onUpdate}
          details={details}
          setDetails={setDetails}
          isEditable={isEditable}
          setEditable={(val) => {
            setEditable(val);
          }}
          setShowPreview={(val) => {
            setShowPreview(val);
          }}
        />
      ) : (
        <ViewDetails
          details={details}
          isEditable={isEditable}
          setEditable={(val) => {
            setEditable(val);
          }}
          setShowPreview={(val) => {
            setShowPreview(val);
          }}
        />
      )}

      <WideModalsWrapper
        height="100vh"
        component={
          <StoryPreviewModal
            setShowPreview={setShowPreview}
            details={details}
          />
        }
        isOpen={showPreview}
        closeModal={() => setShowPreview(false)}
      />
    </div>
  );
}

export default DeckDetails;
