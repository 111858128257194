import { useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import CollectionList from "./Tabs/CollectionList";
import Decks from "components/StoryDeck/Tabs/Decks";

const StoryDeck = () => {
  const [selectedCollection, setSelectedCollection] = useState();

  return (
    <div className="pb-6 px-2.5">
      <div className="pt-6 md:pt-16 lg:mt-5 mb-12 lg:px-4 w-full flex flex-col lg:flex-row items-start lg:space-x-7">
        <div className="w-full lg:w-[30vw] px-2.5 lg:px-2 ">
          <CollectionList
            selectedCollection={selectedCollection}
            setSelectedCollection={(val) => setSelectedCollection(val)}
          />
        </div>

        <div className="w-full lg:w-[35vw] xl:w-2/3 mt-6 lg:mt-0 mb-6 px-4 lg:px-0">
          {selectedCollection && (
            <div className="bg-primary-lavender-darkest rounded-3xl mb-6 mx-1 pr-3 flex flex-row items-center justify-start space-x-4 max-w-max">
              <div className="px-4 py-3.5 text-sm font-lato font-normal text-white">
                Showing Decks for Collection:{" "}
                <b>
                  {selectedCollection ? `${selectedCollection?.title}` : ""}
                </b>
              </div>
              <XIcon
                className="w-4 h-4 mr-4 cursor-pointer text-white"
                onClick={() => {
                  setSelectedCollection();
                }}
              />
            </div>
          )}

          <Decks
            collection={
              selectedCollection?.id === 0 ? null : selectedCollection
            }
            emptyMessage={
              selectedCollection?.id === 0
                ? "Select a Collection to view it's Decks"
                : "The selected Collection has no Decks"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default StoryDeck;
