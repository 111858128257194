// The commented out routes are not being used for now and the associated components are marked as Deprecated ( These are not to be deleted )
import { Redirect } from "react-router-dom";
import SignIn from "pages/AuthPages/SignIn";
import Unauthorized from "layout/Unauthorized";
import { checkAppAccess } from "helpers/utils/common/accessCheck";
import RedirectPage from "pages/AuthPages/Redirect";

import StoryDeck from "pages/Public/Story";
import CGCFYI from "pages/CGC";
import Landing from "pages/Public/Landing";

// Public Routes
// Routes for Authenticating Users
const publicRoutes = [
  // Authentication
  { path: "/decks/:code", component: StoryDeck },
  { path: "/cgc/fyi", component: CGCFYI },
  {
    path: "/auth/signIn",
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          <Redirect to="/resources" />
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <SignIn />
      ),
  },
  {
    path: "/accessDenied",
    component: Unauthorized,
  },
  {
    path: "/redirect",
    component: RedirectPage,
  },
  { path: "/", component: Landing },
];

export { publicRoutes };
