import { useEffect, useState } from "react";
//REDUX
import { useDispatch } from "react-redux";
import DecksModal from "../Modals/DecksModal";
import DeckCard, { DeckCardLoader } from "../DeckCard/index";
import { deleteDeck, getAllDecks } from "config/apis/deck";
import { showToast } from "redux/toaster";
import WideModalsWrapper from "components/Common/Modals/ModalsWrapper/WideModalWrapper";
import ConfirmModal from "components/Common/Modals/ConfirmModal";
import { ALL_ACCESS } from "helpers/constants/passes";
import EmptyState from "components/Common/EmptyState";
import ChronosButton from "components/Common/Buttons";

const Decks = ({ collection, emptyMessage }) => {
  const dispatch = useDispatch();
  const { tags } = JSON.parse(localStorage.getItem("admin"));
  const [decks, setDecks] = useState([]);
  const [originalDecks, setOriginalDecks] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedDeckToEdit, setSelectedDeckToEdit] = useState();
  const [selectedDeckToDelete, setSelectedDeckToDelete] = useState();
  const [fetching, setFetching] = useState(false);
  const [query, setQuery] = useState({});

  const fetchDecks = async () => {
    try {
      setFetching(true);
      let q = { ...query };
      if (collection) {
        q["collectionId"] = collection?.id;
      }

      const response = await getAllDecks(q);
      let data = response.data.data;

      if (!collection) {
        data = data.filter((item) => !item?.collectionId);
      }

      setOriginalDecks(data);
      setDecks(data);
    } catch (err) {
      setOriginalDecks([]);
      console.log("Fetch collection task error", err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchDecks();
  }, [collection, query]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleDelete = async () => {
    try {
      await deleteDeck(selectedDeckToDelete?.id);
      showSuccessNotification("Deleted successfully!");
      fetchDecks();

      setSelectedDeckToDelete(null);
    } catch (err) {
      console.log("objective delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };

  return (
    <>
      <WideModalsWrapper
        component={
          <DecksModal
            type="deck"
            collectionId={collection?.id}
            isOpen={openCreateModal}
            closeModal={() => setOpenCreateModal(false)}
            onCreate={() => fetchDecks()}
            onUpdate={() => fetchDecks()}
          />
        }
        height="100vh"
        isOpen={openCreateModal}
        closeModal={() => setOpenCreateModal(false)}
      />

      <WideModalsWrapper
        component={
          <DecksModal
            type="deck"
            collectionId={collection?.id}
            isOpen={selectedDeckToEdit ? true : false}
            closeModal={() => setSelectedDeckToEdit(false)}
            onCreate={() => fetchDecks()}
            onUpdate={() => fetchDecks()}
            editValues={selectedDeckToEdit}
            isEdit={true}
            onDelete={(val) => {
              setSelectedDeckToDelete(val);
            }}
          />
        }
        height="100vh"
        isOpen={selectedDeckToEdit ? true : false}
        closeModal={() => setSelectedDeckToEdit(false)}
      />

      <ConfirmModal
        isOpen={selectedDeckToDelete ? true : false}
        closeModal={() => setSelectedDeckToDelete(null)}
        onAccept={() => handleDelete("deck")}
        text={
          <>
            Are you sure you want to delete the following Deck :{" "}
            {selectedDeckToDelete?.title}
          </>
        }
      />

      <div className="bg-white rounded-2xl space-y-4 p-4 mb-10">
        <div className="flex">
          <ChronosButton
            text="Add New Deck +"
            primary
            onClick={() => setOpenCreateModal(true)}
          />
        </div>

        {!fetching ? (
          decks?.length > 0 ? (
            <div
              className={`grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-8`}
            >
              {decks?.map((obj, epicIdx) => (
                <DeckCard
                  key={epicIdx}
                  setSelectedDeckToDelete={setSelectedDeckToDelete}
                  setSelectedDeckToEdit={setSelectedDeckToEdit}
                  obj={obj}
                  isEditable={tags?.includes(ALL_ACCESS)}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              image="/assets/images/empty/story.png"
              text={emptyMessage}
            />
          )
        ) : (
          <>
            <div className="mt-6 md:px-4 p-2 rounded-xl">
              <div
                className={`grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-8`}
              >
                {[1, 2, 3, 4, 5].map((obj, epicIdx) => (
                  <DeckCardLoader key={epicIdx} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Decks;
