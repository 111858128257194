import Stories from "react-insta-stories";

function StoryPreview({ details, setShowPreview, updatingStory }) {
  return (
    <div className="mx-auto">
      {details?.images?.length > 0 && details?.images?.find((i) => i?.image) ? (
        updatingStory ? (
          <div
            style={{
              borderRadius: "40px",
              maxWidth: "99vw",
            }}
            className="h-[60vh] font-karla text-lg text-primary-gray-600 w-full bg-white m-auto flex flex-row items-center justify-center"
          >
            <div className="w-20 h-20 rounded-full border border-t-0 border-primary-gray-300 animate-spin bg-transparent" />
          </div>
        ) : (
          <Stories
            stories={details?.images
              ?.filter((i) => i?.image)
              ?.map((i) => i?.image?.url)}
            loop
            keyboardNavigation
            defaultinterval={4000}
            onStoryEnd={(s, st) => console.log("story ended", s, st)}
            onAllStoriesEnd={(s, st) => {
              console.log("all stories ended", s, st);
              if (setShowPreview) setShowPreview(false);
            }}
            onStoryStart={(s, st) => console.log("story started", s, st)}
            storyContainerStyles={{
              overflow: "hidden",
              backgroundColor: "white",
              borderRadius: "40px",
              maxWidth: "99vw",
              margin: "auto",
            }}
          />
        )
      ) : (
        <div
          style={{
            borderRadius: "40px",
            maxWidth: "99vw",
          }}
          className="h-[60vh] font-karla text-lg text-primary-gray-600 w-full bg-white m-auto flex flex-row items-center justify-center"
        >
          <p>Add images to preview story</p>
        </div>
      )}
    </div>
  );
}

export default StoryPreview;
