import React from "react";

function What() {
  return (
    <div className="self-stretch px-4 py-16 lg:p-20 bg-white justify-center items-center gap-2 inline-flex">
      <div className="justify-start items-center gap-10 lg:gap-20 flex lg:flex-row flex-col">
        <div className="lg:w-[40%] text-center lg:text-left text-black text-[32px] lg:text-[120px] font-semibold font-poppins leading-10 lg:leading-[156px] tracking-[1.6px] lg:tracking-[6px]">
          WHAT ARE CGCs?
        </div>
        <div className="self-stretch lg:py-4 flex-col justify-start items-start gap-2 inline-flex">
          <div className="hidden lg:block w-1 grow shrink basis-0 bg-primary-red-500 rounded-[72px]" />
          <div className="lg:hidden block w-[80%] h-1 mx-auto bg-primary-red-500 rounded-[72px]" />
        </div>
        <div className="lg:w-[60%]">
          <span className="text-primary-neutral-800 text-sm lg:text-xl font-light font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            Caarya Growth Centers (CGCs) represent{" "}
          </span>
          <span className="text-primary-red-500 text-sm lg:text-xl font-normal font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            vibrant microcosms of the broader Caarya ecosystem
          </span>
          <span className="text-primary-neutral-800 text-sm lg:text-xl font-light font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            , city-based hubs fostering dynamic interaction among startups,
            students, skill experts, career makers, educators, and gig-workers.{" "}
            <br />
            <br />
            These centers are dedicated to exploring and shaping three vital
            facets of the contemporary and future world -{" "}
          </span>
          <span className="text-primary-red-500 text-sm lg:text-xl font-normal font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            the future of careers, the future of education, and the future of
            work.
            <br />
          </span>
          <span className="text-primary-neutral-800 text-sm lg:text-xl font-light font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            <br />
            Here, students are not mere observers; they are active participants,
          </span>
          <span className="text-primary-red-500 text-sm lg:text-xl font-normal font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            {" "}
            engaging in short-term work commitments with startups
          </span>
          <span className="text-primary-neutral-800 text-sm lg:text-xl font-light font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            , acquiring new skills, and gaining practical experience that sets
            the stage for their career paths. <br />
            <br />
            By providing a platform for such enriched interactions, CGCs not
            only catalyze the growth of startups but also empower students and
            professionals to{" "}
          </span>
          <span className="text-primary-red-500 text-sm lg:text-xl font-normal font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            adapt, evolve, and thrive
          </span>
          <span className="text-primary-neutral-800 text-sm lg:text-xl font-light font-inter leading-6 lg:leading-9 tracking-[0.9px] lg:tracking-wide">
            {" "}
            in the ever-changing landscape of work and education.
          </span>
        </div>
      </div>
    </div>
  );
}

export default What;
