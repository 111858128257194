//ICONS

import { ArrowRight, User } from "@phosphor-icons/react";

/**
 * Displays the Collection Card
 * @param {Function} setCreateDeck -Function to set Boolean Value to open Modal to add Key Results
 * @param {Function} setSelectedDeckToDelete -Function to set the value of Key Result selected to delete
 * @param {Function} setSelectedDeckToEdit -Function to set the value of Key Result selected to edit
 * @param {Function} setSelectedCollection -Function to set the value of Collection
 * @param {Object} obj- details about the Collection
 * @param {Function} setSelectedCollectionToEdit -Function to set the value of Collection to edit
 * @param {Boolean} isEditable -Boolean value indicating whether edit is allowed
 * @returns
 */

function DeckCard({
  setSelectedDeckToDelete,
  setSelectedDeckToEdit,
  obj,
  isEditable,
}) {
  return (
    <>
      <div className="relative group p-4 cursor-pointer focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-lavender-medium bg-primary-lavender-lightest text-primary-lavender-darkest rounded-2xl">
        <div onClick={() => isEditable && setSelectedDeckToEdit(obj)}>
          <span className="bg-primary-lavender-darkest text-2xs text-white rounded-full inline-flex px-2 py-0.5">
            {obj?.code}
          </span>
        </div>
        <div className="mt-2 flex flex-col items-start w-full">
          <h3
            onClick={() => isEditable && setSelectedDeckToEdit(obj)}
            className="card-title font-lato"
          >
            <div className="focus:outline-none w-full">
              {/* <span className="absolute inset-0" aria-hidden="true" /> */}
              <p className="w-full">{obj?.title || "No Title"}</p>
            </div>
          </h3>
          <p
            onClick={() => isEditable && setSelectedDeckToEdit(obj)}
            className="mt-1 text-sm text-gray-500 line-clamp-2 font-lato"
          >
            {obj?.description || "No Description"}
          </p>
          <div
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `${window.location.origin}/decks/${obj?.code}`,
                "__blank"
              );
            }}
            className="mt-4 text-xs font-bold flex flex-row items-center text-gray-700 break-all font-lato border-t pt-2 w-full cursor-pointer"
          >
            Go to Link <ArrowRight size={12} className="ml-1.5" />
          </div>
        </div>
        <span
          className="pofont-lato-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
          aria-hidden="true"
        >
          {obj?.creator?.image?.url ? (
            <img
              src={obj?.creator?.image?.url || ""}
              alt="creator"
              className="h-6 w-6 rounded-full"
            />
          ) : (
            <div className="h-6 w-6 rounded-full flex flex-row items-center justify-center bg-primary-lavender-medium text-primary-lavender-darkest">
              <User size={20} />
            </div>
          )}
        </span>
      </div>
    </>
  );
}

function DeckCardLoader() {
  return (
    <div className="relative group p-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-lavender-medium bg-primary-lavender-lightest text-primary-lavender-darkest rounded-2xl">
      <div>
        <p className="font-lato text-sm h-4 rounded bg-gray-200 animate-pulse w-20 font-normal text-primary-gray-600"></p>
      </div>
      <div className="mt-2 flex flex-col items-start w-full">
        <h3 className="card-title font-lato">
          <div className="focus:outline-none w-full">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="font-lato text-sm h-4 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
          </div>
        </h3>
        <p className="mt-1 text-sm text-gray-500 line-clamp-2 space-y-1 w-full font-lato">
          <p className="font-lato text-sm h-2 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
          <p className="font-lato text-sm h-2 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
          <p className="font-lato text-sm h-2 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
        </p>
        <div className="mt-4 text-xs font-bold text-gray-700 break-all font-lato border-t pt-2 w-full">
          Go to Link ->
        </div>
      </div>
      <span
        className="pofont-lato-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <img src={""} alt="creator" className="h-6 w-6 rounded-full" />
      </span>
    </div>
  );
}

export default DeckCard;

export { DeckCardLoader };
