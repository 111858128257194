/**
 * ShortURLs Card Component under caarya.space
 * @param {String} title
 * @param {String} description
 * @param {Object} type
 * @param {Object} creator -Object containing creators details
 * @param {Function} onClick
 * @returns
 */

import { Menu, Popover } from "@headlessui/react";
import {
  DotsThreeOutline,
  DotsThreeOutlineVertical,
  Pencil,
  Share,
} from "@phosphor-icons/react";

function ShortURLsCard({
  title,
  description,
  type,
  creator,
  onClick,
  short_url,
}) {
  return (
    <div className="space-y-2">
      <div className="bg-primary-gray-50 rounded-lg p-2 w-full aspect-video">
        <div className="flex flex-row items-stretch justify-between space-x-2.5">
          <div className="rounded-2xl pb-[1px] flex flex-row items-center justify-center px-2.5 bg-primary-lavender-darkest text-white font-lato text-3xs font-semibold">
            {type?.name}
          </div>
          <Popover className="relative">
            <Popover.Button>
              <DotsThreeOutlineVertical size={20} weight="fill" />
            </Popover.Button>

            <Popover.Panel className="absolute right-0 z-10 divide-y font-lato text-xs text-primary-gray-600 font-medium bg-white shadow-sm w-32">
              <div
                onClick={onClick}
                className="py-1 flex flex-row items-center space-x-2.5 px-3 text-primary-lavender-darkest hover:bg-primary-lavender-lightest cursor-pointer"
              >
                <Pencil size={20} className="text-primary-lavender-medium" />
                <p>Edit</p>
              </div>
              <div
                onClick={async (e) => {
                  if (navigator.share) {
                    try {
                      await navigator
                        .share({ url: short_url, title: title, text: "" })
                        .then(() =>
                          console.log(
                            "Hooray! Your content was shared to tha world"
                          )
                        );
                    } catch (error) {
                      console.log(
                        `Oops! I couldn't share to the world because: ${error}`
                      );
                    }
                  } else {
                    // fallback code
                    console.log(
                      "Web share is currently not supported on this browser. Please provide a callback"
                    );
                  }
                }}
                className="py-1 flex flex-row items-center space-x-2.5 px-3 text-primary-lavender-darkest hover:bg-primary-lavender-lightest cursor-pointer"
              >
                <Share size={20} className="text-primary-lavender-medium" />
                <p>Share</p>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      <h1 className="text-black font-lato text-sm font-medium">{title}</h1>
      <h1 className="text-primary-gray-250 font-lato text-xs font-light">
        {description}
      </h1>
    </div>
  );
}

function ShortUrlLoader() {
  return (
    <div className="space-y-2">
      <div className="bg-primary-gray-50 rounded-lg p-2 w-full aspect-video">
        <div className="flex flex-row items-stretch justify-between space-x-2.5">
          <div className="rounded-2xl w-20 pb-[1px] flex flex-row items-center justify-center px-2.5 bg-primary-lavender-darkest text-white font-lato text-3xs font-semibold"></div>
          <Popover className="relative">
            <Popover.Button>
              <DotsThreeOutlineVertical size={20} weight="fill" />
            </Popover.Button>

            <Popover.Panel className="absolute right-0 z-10 divide-y font-lato text-xs text-primary-gray-600 font-medium bg-white shadow-sm w-32">
              <div className="py-1 flex flex-row items-center space-x-2.5 px-3 text-primary-lavender-darkest hover:bg-primary-lavender-lightest cursor-pointer">
                <Pencil size={20} className="text-primary-lavender-medium" />
                <p>Edit</p>
              </div>
              <div className="py-1 flex flex-row items-center space-x-2.5 px-3 text-primary-lavender-darkest hover:bg-primary-lavender-lightest cursor-pointer">
                <Share size={20} className="text-primary-lavender-medium" />
                <p>Share</p>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      <h1 className="text-black font-lato text-sm font-medium">
        {" "}
        <p className="font-lato text-sm h-4 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
      </h1>
      <h1 className="text-primary-gray-250 font-lato text-xs font-light">
        <p className="font-lato text-sm h-2 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
        <p className="font-lato text-sm h-2 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
      </h1>
    </div>
  );
}

export default ShortURLsCard;
export { ShortUrlLoader };
