import { fyiInstance, getHeader } from "..";

export const createCollection = (body) =>
  fyiInstance.post(`/collection`, body, getHeader());

export const updateCollection = (id, body) =>
  fyiInstance.patch(`/collection/${id}`, body, getHeader());

export const getCollection = (CollectionId) =>
  fyiInstance.get(`/collection/${CollectionId}`, getHeader());

export const getAllCollections = (query = {}) =>
  fyiInstance.get(
    `/collection${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

export const deleteCollection = (CollectionId) =>
  fyiInstance.delete(`/collection/${CollectionId}`, getHeader());
