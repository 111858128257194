import { useEffect, useState } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FilePicker } from "react-file-picker";
import { useDispatch, useSelector } from "react-redux";
import { XIcon, CheckIcon, TrashIcon } from "@heroicons/react/outline";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router";
import { getAllCollections } from "config/apis/collection";
import { showToast } from "redux/toaster";
import { deleteFile, uploadFile } from "config/apis/common";
import ConfirmModal from "components/Common/Modals/ConfirmModal";
import SimpleInput from "components/Common/Inputs/SimpleInput";
import SimpleTextArea from "components/Common/Inputs/SimpleTextArea";
import DropdownInput from "components/Common/Inputs/DropdownInput";
import { deleteDeck, updateDeck } from "config/apis/deck";
import { Plus } from "@phosphor-icons/react";

function EditDetails({ details, setDetails, setEditable, setUpdatingStory }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fieldErrors, setFieldErrors] = useState({});
  const [list, setList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedSeq, setSelectedSeq] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState();

  useEffect(() => {
    fetchCollection();
  }, []);

  const fetchCollection = async () => {
    try {
      const response = await getAllCollections();
      let newcollection = response.data.data || [];
      setList(newcollection);
    } catch (err) {
      console.log(err);
    }
  };
  const handleFilePickerError = (err) => {
    console.log("File Picker Error", err);
    const message = `Please select an appropriate image file!`;
    handleError(message);
  };
  const handleError = (err) => {
    dispatch(showToast({ message: err, type: "error" }));
  };

  const handleFilePicker = async (pickedFile) => {
    if (pickedFile.size > 10485760) {
      handleError("File size cannot exceed 10MB");
      return;
    }
    setUploading(true);
    try {
      const response = await uploadFile({ file: pickedFile });
      const { file } = response.data.data;
      let temp = [...details.images];
      temp?.map((i) => {
        if (i?.seq === selectedSeq) i["image"] = file;
      });
      setDetails({ ...details, images: temp });
    } catch (err) {
      console.log("File Upload error", err);
      const message = `File Upload Error!`;
      handleError(message);
    }
    setUploading(false);
  };

  const removeFile = async (fileId) => {
    try {
      const response = await deleteFile({ id: fileId });
    } catch (err) {
      console.log("File Delete error", err);
    }
  };

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleUpdate = async () => {
    let { images, ...body } = details;
    body["imagesIds"] = images?.map((image) => image?.image?.id);
    setUpdating(true);
    try {
      const response = await updateDeck(details?.id, body);
      showSuccessNotification("Updated successfully!");
      setEditable(false);
    } catch (e) {
      console.log("error", e);

      switch (e.response?.status) {
        case 422:
          setFieldErrors(e.response.data.data);
          showErrorNotification("Invalid or Insufficient Parameters.");
          break;

        default:
          showErrorNotification(e.response?.data?.message);
      }
    }
    setUpdating(false);
  };

  const handleDelete = async () => {
    try {
      await deleteDeck(selectedToDelete.id);
      showSuccessNotification("Deck deleted successfully!");
      history.goBack();
    } catch (err) {
      console.log("Library delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };

  return (
    <>
      <ConfirmModal
        isOpen={selectedToDelete ? true : false}
        closeModal={() => {
          setSelectedToDelete(null);
          setUpdatingStory(false);
        }}
        onAccept={() => handleDelete()}
        text={
          <>
            Are you sure you want to delete the Deck: {selectedToDelete?.title}?
          </>
        }
      />
      <div className="bg-white p-6 rounded-2xl">
        <div className="px-3 relative">
          <div className="absolute top-3 right-4 cursor-pointer flex flex-row items-center">
            <CheckIcon
              onClick={() => {
                handleUpdate();
              }}
              className="text-primary-gray-600 h-4 mr-2.5"
            />
            <TrashIcon
              className="text-primary-red-500 h-4"
              onClick={() => {
                setUpdatingStory(true);
                setSelectedToDelete(details);
              }}
            />
          </div>
          <div className="grid gap-5 w-full">
            <div>
              <label className="input-label">Title</label>
              <TextareaAutosize
                minRows={1}
                placeholder="Title"
                className={`w-full appearance-none pb-1.5 border-b border-color-gray-350 text-primary-gray-600 font-lato font-normal leading-6 placeholder:text-gray-200 bg-transparent focus:outline-none focus:ring-transparent`}
                invalid={fieldErrors?.msg?.title}
                value={details?.title}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <SimpleInput
              label="Code"
              field="code"
              details={details}
              setDetails={setDetails}
              showError={Object.keys(fieldErrors).length > 0}
              errorMessage={fieldErrors?.details?.msg}
            />
            <SimpleTextArea
              label="Description"
              field="description"
              details={details}
              setDetails={setDetails}
            />

            <DropdownInput
              label={"Collection"}
              value={details?.collectionId}
              setValue={(val) => {
                setDetails({
                  ...details,
                  collectionId: val,
                });
              }}
              list={list.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-2xl">
        <div className="px-3 relative mb-3">
          <label className="input-label">Images</label>
          <div className="lg:max-h-50vh ">
            <Slider
              arrows={false}
              dots
              infinite
              adaptiveHeight={true}
              speed={700}
              slidesToShow={3}
              slidesToScroll={1}
              responsive={[
                {
                  breakpoint: 1320,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1190,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 550,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {details?.images?.map((img, idx) => {
                return (
                  <div key={idx} className="pb-9">
                    <div
                      onClick={() => {
                        setSelectedSeq(img?.seq);
                      }}
                      className="p-2.5 md:p-4 block"
                    >
                      {img?.image ? (
                        <div className="relative">
                          <img
                            src={
                              img?.image?.url ||
                              "https://images.unsplash.com/photo-1670092498973-3111f63d71fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1947&q=80"
                            }
                            alt=""
                            className="w-full object-contain rounded-md"
                          />
                          <div className="ml-2 absolute -top-1 -right-1 bg-primary-lavender-lightest rounded-full p-1 flex flex-row items-center justify-center">
                            <XIcon
                              onClick={() => {
                                setUpdatingStory(true);
                                removeFile(img?.image?.id);
                                let temp = [...details.images];
                                temp.map((i) => {
                                  if (i?.seq == img?.seq) delete i?.image;
                                });
                                console.log(temp);
                                try {
                                  setDetails({
                                    ...details,
                                    images: temp,
                                  });
                                } catch (e) {
                                  console.log(e);
                                } finally {
                                  setUpdatingStory(false);
                                }
                              }}
                              className="text-primary-red-500 h-3 z-50 cursor-pointer ease-in-out hover:scale-110 transition"
                            />
                          </div>
                        </div>
                      ) : (
                        <FilePicker
                          extensions={["png", "jpeg", "jpg", "svg"]}
                          onChange={handleFilePicker}
                          onError={handleFilePickerError}
                          maxSize={6000}
                          multiple
                        >
                          <div className="col-span-1 cursor-pointer empty-slot min-h-[20vh] flex flex-row items-center justify-center w-full mx-auto task-board-card-v2">
                            {selectedSeq === img?.seq && uploading ? (
                              <div className="w-10 h-10 rounded-full border border-t-0 border-primary-gray-300 animate-spin bg-transparent" />
                            ) : (
                              <div className="col-span-1 cursor-pointer empty-slot h-[20vh] rounded-2xl border border-primary-gray-350 border-dashed flex flex-row items-center justify-center w-full mx-auto task-board-card-v2">
                                <div className="flex flex-row items-center justify-center rounded-full bg-primary-gray-60">
                                  <Plus size={20} />
                                </div>
                              </div>
                            )}
                          </div>
                        </FilePicker>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditDetails;
