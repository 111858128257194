import React, { useEffect, useState } from "react";

import { useHistory, useRouteMatch } from "react-router";
//Redux
import { useDispatch } from "react-redux";
//Ui
import { XIcon } from "@heroicons/react/solid";
//Moments
import moment from "moment";
//APIS
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ChronosButton from "components/Common/Buttons";
import { showToast } from "redux/toaster";
import { getAllLibrary } from "config/apis/library";
import { getAllCollections } from "config/apis/collection";
import { createDeck, updateDeck } from "config/apis/deck";
import SimpleInput from "components/Common/Inputs/SimpleInput";
import DropdownInput from "components/Common/Inputs/DropdownInput";
import SimpleTextArea from "components/Common/Inputs/SimpleTextArea";

const defaultValue = {
  quarter: `Quarter ${parseInt(moment().quarter())}`,
  year: parseInt(moment().format("YYYY")),
};

const DecksModal = ({
  onCreate,
  onUpdate,
  editValues,
  isEdit,
  closeModal,
  isOpen,
  onDelete,
  type,
  libraryId,
  collectionId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const route = useRouteMatch();

  const [data, setData] = useState(defaultValue);
  const [fieldErrors, setFieldErrors] = useState({});
  const [focusTitle, setFocusTitle] = useState(isOpen);
  const [list, setList] = useState([]);

  // progress state
  const [creating, setCreating] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  useEffect(() => {
    if (editValues) {
      setData(editValues);
    }
  }, [editValues]);

  useEffect(() => {
    if (!editValues) {
      if (libraryId) {
        setData({ ...data, libraryId });
      }
      if (collectionId) {
        setData({ ...data, collectionId });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setFieldErrors({});

      if (isOpen) {
        setFocusTitle(true);
      }
    }

    return () => {
      setFocusTitle(false);
      isMounted = false;
    };
  }, [isOpen]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && (type == "collection" || type == "deck")) {
      fetchList();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchList = async () => {
    try {
      let query = {};
      if (type == "deck" && libraryId) {
        query["libraryId"] = libraryId;
      }

      const response =
        type == "collection"
          ? await getAllLibrary(query)
          : await getAllCollections(query);

      let newList = response.data.data || [];

      setList(newList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    let body = { ...data };

    setCreating(true);
    try {
      const response = await createDeck(body);

      onCreate();
      showSuccessNotification("Created Successfully!");
      closeModal();
      setData(defaultValue);
    } catch (e) {
      console.log(e);
      switch (e.response?.status) {
        case 422:
          setFieldErrors(e.response.data.data);
          showErrorNotification("Invalid or Insufficient Parameters.");
          break;
        case 401:
          showErrorNotification(e.response?.data?.message);
          break;
        case 409:
          showErrorNotification(e.response?.data?.message);
          break;
        case 402:
          showErrorNotification(e.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
    setCreating(false);
  };

  const handleUpdate = async () => {
    let body = { ...data };

    setUpdating(true);
    try {
      const response = await updateDeck(data?.id, body);
      showSuccessNotification("Updated successfully!");
      onUpdate();
      closeModal();
      setData(defaultValue);
    } catch (e) {
      console.log("error", e);

      switch (e.response?.status) {
        case 422:
          setFieldErrors(e.response.data.data);
          showErrorNotification("Invalid or Insufficient Parameters.");
          break;
        case 401:
          showErrorNotification(e.response?.data?.message);
          break;
        case 409:
          showErrorNotification(e.response?.data?.message);
          break;
        case 402:
          showErrorNotification(e.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
    setUpdating(false);
  };

  return (
    <>
      <div className="bg-white p-6 w-full lg:max-w-lg mx-auto relative rounded-2xl">
        <div className="flex flex-row items-start justify-between">
          <h5 className="font-lato font-bold text-xl text-primary-gray-600 flex flex-col items-start -space-y-2">
            <p className="mr-3.5">
              {isEdit ? "Update " : "Add New "} {type}
            </p>
            {isEdit && type == "deck" ? (
              <ChronosButton
                tertiary
                text="View Full Details"
                onClick={() => {
                  history.push(`/storyDeck/${editValues.id}`);
                }}
              />
            ) : (
              <></>
            )}
          </h5>
          <div className="flex flex-row items-center space-x-3">
            <button
              aria-label="Close"
              type="button"
              onClick={() => closeModal()}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        <div className="mt-3.5 flex space-y-5 flex-col w-full">
          <div>
            <label className="input-label">Title</label>
            <TextareaAutosize
              minRows={1}
              placeholder="Title"
              className={`w-full appearance-none pb-1.5 border-b border-color-gray-350 text-primary-gray-600 font-lato font-normal leading-6 placeholder:text-gray-200 bg-transparent focus:outline-none focus:ring-transparent`}
              invalid={fieldErrors?.msg?.title}
              value={data.title}
              onChange={(e) => {
                setData({
                  ...data,
                  title: e.target.value,
                });
              }}
            />
          </div>
          {type == "deck" && (
            <SimpleInput
              label="Code"
              field="code"
              details={data}
              setDetails={setData}
              showError={Object.keys(fieldErrors).length > 0}
              errorMessage={fieldErrors?.data?.msg}
            />
          )}
          <SimpleTextArea
            label="Description"
            field="description"
            details={data}
            setDetails={setData}
          />
          {(type == "collection" || type == "deck") && (
            <DropdownInput
              label={type == "collection" ? "Library" : "Collection"}
              value={
                type == "collection" ? data?.libraryId : data?.collectionId
              }
              setValue={(val) => {
                type == "collection"
                  ? setData({
                      ...data,
                      libraryId: val,
                    })
                  : setData({
                      ...data,
                      collectionId: val,
                    });
              }}
              list={list.map((e) => ({
                label: e?.title,
                value: e?.id,
              }))}
            />
          )}
          {type == "collection" && (
            <DropdownInput
              label="Category"
              value={data?.category}
              setValue={(val) => {
                setData({
                  ...data,
                  category: val,
                });
              }}
              list={[{ label: "C1", value: "c1" }]}
            />
          )}
        </div>

        <div className="mt-8 flex w-full flex-row-reverse items-center space-x-4 justify-between">
          <div className="flex flex-row items-center justify-end space-x-2">
            <ChronosButton
              text="Cancel"
              secondary
              onClick={() => {
                closeModal();
              }}
            />
            {updating || creating ? (
              <div className="w-5 h-5 bg-transparent border border-color-indigo-650 rounded-full border-t-0 animate-spin" />
            ) : (
              <ChronosButton
                text={isEdit ? "Update" : "Add"}
                primary
                onClick={() => {
                  isEdit ? handleUpdate() : handleSubmit();
                }}
              />
            )}
          </div>
          {isEdit && (
            <div className="flex">
              <ChronosButton
                text="Delete"
                tertiary
                red
                onClick={() => {
                  onDelete(data);
                  closeModal();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DecksModal;
