/* eslint-disable no-undef */
let API_END_POINT = "";

switch (process.env.REACT_APP_ENV) {
  case "production":
    // Endpoint for Production website
    API_END_POINT = "https://chronos-core-prod-tk4gwh76qa-el.a.run.app";
    break;
  case "staging":
    // Endpoint for Staging website
    API_END_POINT = "https://chronos-core-dev-tk4gwh76qa-el.a.run.app";
    break;
  case "development":
    // Endpoint for Development website
    API_END_POINT = "https://chronos-core-dev-tk4gwh76qa-el.a.run.app";
    break;
  case "local":
    // Endpoint for Local
    API_END_POINT = "http://localhost:4193";
    break;
  default:
    // Default Endpoint
    API_END_POINT = "https://chronos-core-prod-tk4gwh76qa-el.a.run.app";
}

export default API_END_POINT;
