import React, { useEffect, useState } from "react";
import * as ShortUrlAPI from "config/apis/shortUrl";

import ChronosButton from "components/Common/Buttons";
import EmptyState from "components/Common/EmptyState/index";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "redux/toaster";
import WideModalsWrapper from "../Modals/ModalsWrapper/WideModalWrapper";
import NewShortUrlModal from "./Modals/NewShortUrl";
import ConfirmModal from "../Modals/ConfirmModal";
import SearchBox from "../Inputs/SearchBox";
import ShortURLsCard, { ShortUrlLoader } from "./Card";

/**
 * Short URLs Table Component
 * @returns
 */

const ShortUrlsView = ({ type }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const [shortUrls, setShortUrls] = useState([]);
  const [search, setSearch] = useState();
  const [fetching, setFetching] = useState(false);
  const [selectedUrlToDelete, setSelectedUrlToDelete] = useState();
  const [urlToUpdate, setUrlToUpdate] = React.useState();

  const [newLinkModal, setNewLinkModal] = useState(false);

  useEffect(() => fetchAllShortUrls(), [type]);

  const handleDelete = async () => {
    try {
      await ShortUrlAPI.destroy(selectedUrlToDelete.id);
      dispatch(showToast({ message: "Short URL deleted successfully!" }));
      setSelectedUrlToDelete(null);
      fetchAllShortUrls();
    } catch (err) {
      console.log("Goal delete error", err);
      dispatch(showToast({ message: err?.response?.data?.message }));
    }
  };

  const fetchAllShortUrls = async () => {
    setFetching(true);
    try {
      const response = await ShortUrlAPI.findAll({
        typeName: type,
        creator_id: user?.id,
      });
      let newLinks = response.data.data.response;
      setShortUrls(newLinks);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  return (
    <>
      <WideModalsWrapper
        height="90vh"
        component={
          <NewShortUrlModal
            isOpen={newLinkModal}
            closeModal={() => setNewLinkModal(false)}
            onCreate={(shortUrl) => fetchAllShortUrls()}
          />
        }
        isOpen={newLinkModal}
        closeModal={() => setNewLinkModal(false)}
      />
      <WideModalsWrapper
        height="90vh"
        component={
          <NewShortUrlModal
            isOpen={!!urlToUpdate}
            closeModal={() => setUrlToUpdate(null)}
            editValues={urlToUpdate}
            onUpdate={() => fetchAllShortUrls()}
            isEdit
            setSelectedUrlToDelete={(val) => {
              setSelectedUrlToDelete(val);
            }}
          />
        }
        isOpen={!!urlToUpdate}
        closeModal={() => setUrlToUpdate(null)}
      />
      <ConfirmModal
        isOpen={selectedUrlToDelete ? true : false}
        closeModal={() => setSelectedUrlToDelete(null)}
        onAccept={() => handleDelete()}
        text={
          <>
            Are you sure you want to delete the following URL:{" "}
            {selectedUrlToDelete?.title}
          </>
        }
      />

      <div className="p-6 rounded-2xl bg-white space-y-6">
        <div className="flex flex-row items-center justify-between space-x-4">
          <SearchBox
            placeholder="Start typing to search..."
            search={search}
            setSearch={setSearch}
          />
          <div className="flex">
            <ChronosButton
              primary
              text="Create Short Link +"
              onClick={() => setNewLinkModal(true)}
            />
          </div>
        </div>

        {!fetching ? (
          shortUrls?.length > 0 ? (
            <>
              <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4 space-y-4 sm:space-y-0">
                {shortUrls.map((item, ind) => {
                  return (
                    <ShortURLsCard
                      key={ind}
                      {...item}
                      onClick={() => setUrlToUpdate(item)}
                    />
                  );
                })}
              </div>{" "}
            </>
          ) : (
            <EmptyState message={`No ${type} found!`} />
          )
        ) : (
          <>
            <div className="sm:grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4 space-y-4 sm:space-y-0">
              {[1, 2, 3, 4, 5, 6].map((item, ind) => {
                return <ShortUrlLoader key={ind} {...item} />;
              })}
            </div>{" "}
          </>
        )}
      </div>
    </>
  );
};

export default ShortUrlsView;
