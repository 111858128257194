import React, { useEffect, useState } from "react";
//Redux
import { useDispatch } from "react-redux";

import * as ShortUrlAPI from "config/apis/shortUrl";
import { XIcon } from "@heroicons/react/solid";
import ChronosButton from "components/Common/Buttons";
import { showToast } from "redux/toaster";
import SimpleInput from "components/Common/Inputs/SimpleInput";
import DropdownInputForObject from "components/Common/Inputs/DropdownInputForObject";
import SimpleTextArea from "components/Common/Inputs/SimpleTextArea";

const defaultValue = {
  title: "",
  description: "",
  category: "",
  url_code: "",
  long_url: "",
};

const NewShortUrlModal = ({
  onCreate,
  onUpdate,
  editValues,
  isEdit,
  closeModal,
  setSelectedUrlToDelete,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const [newShortUrlData, setNewShortUrlData] = useState(defaultValue);
  const [fieldErrors, setFieldErrors] = useState({});
  const [categories, setCategories] = React.useState([]);

  const [creating, setCreating] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const fetchCategories = async () => {
    try {
      const response = await ShortUrlAPI.findAll({ type: true });
      const fetchedCategories = response.data.data.response;
      setCategories(fetchedCategories);
    } catch (err) {
      console.log("Categories fetch error", err);
    }
  };

  useEffect(() => {
    if (editValues) setNewShortUrlData(editValues);
  }, [editValues]);

  useEffect(() => {
    setFieldErrors({});
    fetchCategories();
  }, [isOpen]);

  const handleChange = (value, field) => {
    var data = { ...newShortUrlData };
    data[field] = value;
    setNewShortUrlData(data);
  };

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleCreate = async () => {
    setCreating(true);
    const { type, ...body } = newShortUrlData;
    // body["type_id"] = type?.id;
    try {
      await ShortUrlAPI.create(body);
      closeModal();
      showSuccessNotification("Short URL created successfully!");
      onCreate(newShortUrlData);
      setNewShortUrlData(defaultValue);
      setFieldErrors({});
    } catch (err) {
      console.log(err);
      switch (err.response?.status) {
        case 422:
          setFieldErrors(err.response.data.data);
          break;
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }
    setCreating(false);
  };

  const handleUpdate = async () => {
    setUpdating(true);
    const { type, ...body } = newShortUrlData;
    // body["type_id"] = type?.id;
    try {
      await ShortUrlAPI.update({ id: body?.id, update: body });
      closeModal();
      showSuccessNotification("Short URL updated successfully!");
      onUpdate(newShortUrlData);
      setNewShortUrlData(defaultValue);
      setFieldErrors({});
    } catch (err) {
      console.log(err);
      switch (err.response?.status) {
        case 422: {
          let error = {};
          let { data } = err.response.data;
          for (let key of Object.keys(data)) {
            error[key.split(".")[1]] = data[key];
          }
          setFieldErrors(error);
          break;
        }
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }
    setUpdating(false);
  };

  return (
    <>
      <div className="bg-white p-6 w-full lg:max-w-lg mx-auto relative rounded-2xl">
        <div className="flex flex-row items-center justify-between">
          <h5 className="font-lato font-bold text-xl text-primary-gray-600 flex flex-row items-center">
            {`${isEdit ? "Update" : "Add New"} Short URL`}
          </h5>
          <div className="flex flex-row items-center space-x-3">
            <button
              aria-label="Close"
              type="button"
              onClick={() => closeModal()}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        <div className="mt-3.5 flex space-y-5 flex-col w-full">
          <SimpleInput
            label="Long URL"
            field="long_url"
            details={newShortUrlData}
            setDetails={setNewShortUrlData}
            showError={Object.keys(fieldErrors).length > 0}
            errorMessage={fieldErrors?.long_url?.msg}
          />
          <SimpleInput
            label="URL Code"
            field="url_code"
            details={newShortUrlData}
            setDetails={setNewShortUrlData}
            showError={Object.keys(fieldErrors).length > 0}
            errorMessage={fieldErrors?.url_code?.msg}
          />
          <DropdownInputForObject
            label="Category"
            field="type_id"
            details={newShortUrlData}
            setDetails={setNewShortUrlData}
            list={categories.map((item) => ({
              value: item?.id,
              label: item?.name,
            }))}
          />
          <SimpleInput
            label="Title"
            field="title"
            details={newShortUrlData}
            setDetails={setNewShortUrlData}
            showError={Object.keys(fieldErrors).length > 0}
            errorMessage={fieldErrors?.title?.msg}
          />

          <SimpleTextArea
            label="Description"
            field="description"
            details={newShortUrlData}
            setDetails={setNewShortUrlData}
          />

          {/* <DropdownInputForObject
            label="Access Control"
            field="access_control"
            details={newShortUrlData}
            setDetails={setNewShortUrlData}
            list={AccessControls}
          /> */}

          {isEdit && (
            <div className="flex">
              <ChronosButton
                tertiary
                red
                text="Delete"
                onClick={() => {
                  setSelectedUrlToDelete(newShortUrlData);
                  closeModal();
                }}
              />
            </div>
          )}
        </div>

        <div className="mt-8 flex w-full flex-row items-center space-x-4 justify-center">
          <ChronosButton
            text="Cancel"
            secondary
            onClick={() => {
              closeModal();
            }}
          />
          {updating || creating ? (
            <div className="w-5 h-5 bg-transparent border border-color-indigo-650 rounded-full border-t-0 animate-spin" />
          ) : (
            <ChronosButton
              text={isEdit ? "Update" : "Add"}
              primary
              onClick={() => {
                isEdit ? handleUpdate() : handleCreate();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NewShortUrlModal;
