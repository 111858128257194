import ShortUrlsView from "components/Common/Resources";
import Tabs from "components/Common/Tabs";
import { useState } from "react";

const tabs = [
  { label: "Kits", value: "Kits" },
  { label: "FYI", value: "FYI" },
  { label: "Decks", value: "Info Deck" },
  { label: "Locker", value: "Locker" },
];

function Resources() {
  const [selectedTab, setSelectedTab] = useState("Kits");
  return (
    <div className="pb-6 px-2.5">
      <div className="pt-6 md:pt-16 lg:mt-5 mb-12 md:px-4 w-full">
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <ShortUrlsView type={selectedTab} />
      </div>
    </div>
  );
}

export default Resources;
