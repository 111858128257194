import { fyiInstance, getHeader } from "..";

export const createDeck = (body) =>
  fyiInstance.post(`/deck`, body, getHeader());

export const updateDeck = (id, body) =>
  fyiInstance.patch(`/deck/${id}`, body, getHeader());

export const getDeck = (DeckId) =>
  fyiInstance.get(`/deck/${DeckId}`, getHeader());

export const getAllDecks = (query = {}) =>
  fyiInstance.get(
    `/deck${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

export const deleteDeck = (DeckId) =>
  fyiInstance.delete(`/deck/${DeckId}`, getHeader());
