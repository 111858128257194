import { configureStore } from "@reduxjs/toolkit";

import toasterReducer from "./toaster";
import userReducer from "./user";

const store = configureStore({
  reducer: {
    user: userReducer,
    toaster: toasterReducer,
  },
});

export default store;
