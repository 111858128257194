import React from "react";

function Scholarship() {
  return (
    <div className="self-stretch p-20 bg-stone-800 justify-center items-center gap-[120px] inline-flex">
      <div className="lg:w-1/2 px-[15.01px] py-[15.10px] justify-center items-center flex">
        <img
          src="/assets/images/cgc/scholarship.svg"
          className="w-[289.99px] h-[289.79px] relative"
        ></img>
      </div>
      <div className="flex-col justify-start items-start gap-10 inline-flex">
        <div className="text-white text-5xl font-bold font-poppins leading-[72px] tracking-[3.84px]">
          Work <br />
          Scholarships
        </div>
        <div className="w-[120px] h-1 bg-white rounded-[104px]" />
        <div className="self-stretch h-20 text-white text-base font-light font-inter leading-normal tracking-wide">
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
          fugit, sed quia consequuntur ma Nemo enim ipsam voluptatem quia
          voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma
        </div>
        <div className="px-8 py-[18px] bg-primary-red-500 rounded-[96px] shadow justify-center items-center gap-2.5 inline-flex">
          <div className="text-white text-lg font-semibold font-inter leading-[27px] tracking-wide">
            Apply For Scholarship
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scholarship;
