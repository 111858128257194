import { Stack, Cards } from "@phosphor-icons/react";

const mobileBottomNav = [
  {
    name: "Resources",
    path: "/resources",
    icon: <Stack size={25} />,
  },

  {
    name: "",
    path: "/",
    image: "/assets/caaryaLogos/logo.svg",
    selectedImage: "/assets/caaryaLogos/logo.svg",
  },
  {
    name: "Story Deck",
    path: "/storyDeck",
    icon: <Cards size={25} />,
  },
];

const laptopNav = [
  {
    name: "Resources",
    path: "/resources",
    icon: <Stack size={25} />,
  },

  {
    name: "Story Deck",
    path: "/storyDeck",
    icon: <Cards size={25} />,
  },
];

export { mobileBottomNav, laptopNav };
