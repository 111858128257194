import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DeckDetails from "components/StoryDeck/DeckPage/DeckDetails/index";
import StoryPreview from "components/StoryDeck/DeckPage/StoryPreview/index";
import { getDeck } from "config/apis/deck";

function DeckPage() {
  const params = useParams();
  const { id } = params;
  const [updatingStory, setUpdatingStory] = useState(true);
  const [details, setDetails] = useState({
    title: "Deck Title",
    description: "Description",
    images: [
      {
        image: {
          url: "https://images.unsplash.com/photo-1612979168796-bcae1575b8c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Q2hyaXN0bWFzJTIwdHJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        },
        seq: 1,
      },
      {
        seq: 2,
      },
      {
        image: {
          url: "https://images.unsplash.com/photo-1595424377378-a253ca4a7486?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fENocmlzdG1hcyUyMHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        },
        seq: 3,
      },
      {
        image: {
          url: "https://images.unsplash.com/photo-1612979168796-bcae1575b8c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Q2hyaXN0bWFzJTIwdHJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        },
        seq: 4,
      },
      {
        seq: 5,
      },
      {
        image: {
          url: "https://images.unsplash.com/photo-1595424377378-a253ca4a7486?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fENocmlzdG1hcyUyMHRyZWV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        },
        seq: 6,
      },
      {
        image: {
          url: "https://images.unsplash.com/photo-1612979168796-bcae1575b8c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Q2hyaXN0bWFzJTIwdHJlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        },
        seq: 7,
      },
      {
        seq: 8,
      },
      {
        seq: 9,
      },
      {
        seq: 10,
      },
    ],
  });
  const [fetching, setFetching] = useState(false);

  const fetchDeck = async () => {
    setFetching(true);
    try {
      const response = await getDeck(id);
      let fetcheddetails = response.data.data;
      let images = [];
      fetcheddetails?.images?.map((image, index) => {
        images.push({ image: image, seq: index + 1 });
      });
      for (var i = 0; i < 10; i++) {
        if (images?.length >= i + 1) {
        } else {
          images.push({ seq: i + 1 });
        }
      }
      fetcheddetails["images"] = images;

      console.log(fetcheddetails);
      setDetails(fetcheddetails);
      setUpdatingStory(false);
    } catch (err) {
      console.log("Fetch details error", err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchDeck();
  }, [id]);

  return (
    <div className="lg:pt-16 lg:px-4 w-full flex flex-col-reverse lg:flex-row items-start lg:space-x-7 pb-10 lg:pb-0">
      <div className="w-full lg:w-[30vw] lg:px-2 block">
        <StoryPreview details={details} updatingStory={updatingStory} />
      </div>

      <div className="w-full lg:w-[35vw] xl:w-2/3 mt-6 lg:mt-0 mb-6 lg:px-0">
        <DeckDetails
          setUpdatingStory={setUpdatingStory}
          onUpdate={() => {
            fetchDeck();
          }}
          details={details}
          setDetails={setDetails}
        />
      </div>
    </div>
  );
}

export default DeckPage;
