import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pencil, Plus } from "@phosphor-icons/react";

function ViewDetails({ details, setShowPreview, setEditable }) {
  return (
    <>
      <div className="bg-white p-6 rounded-2xl">
        <div className="px-3 relative">
          <div className="absolute top-3 right-4  flex flex-row items-center">
            {/* <EyeIcon
            onClick={() => {
              setShowPreview(true);
            }}
            className="text-primary-indigo-550 h-4 mr-2.5"
          /> */}
            <Pencil
              size={16}
              className="cursor-pointer text-primary-lavender-darkest transform transition duration-500 hover:scale-105"
              onClick={() => {
                setEditable(true);
              }}
            />
          </div>
          <div className="flex flex-col items-start space-y-5 w-full">
            <div className="w-full flex flex-col items-start space-y-1.5">
              <h1 className="text-xl font-karla text-primary-gray-600 font-bold text-center lg:text-left ">
                {details?.title || ""}
              </h1>
              <h1
                className={`w-full text-left font-semibold text-sm font-lato text-primary-gray-350 `}
              >
                {details?.code}
              </h1>{" "}
              <h1
                className={`w-full text-left  text-xs font-lato text-primary-gray-350 font-normal `}
              >
                {details?.description || ""}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-6 rounded-2xl">
        <div className="px-3 relative pb-5">
          <h1 className="font-karla mb-1.5 text-primary-gray-600 font-medium text-left ">
            Images
          </h1>
          <div className="lg:max-h-50vh">
            <Slider
              arrows={false}
              dots
              infinite
              adaptiveHeight={true}
              speed={700}
              slidesToShow={3}
              slidesToScroll={1}
              responsive={[
                {
                  breakpoint: 1320,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1190,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 550,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {details?.images?.map((img, idx) => {
                return (
                  <div key={idx} className="pb-9">
                    <div className="p-2.5 md:p-4 block">
                      {img?.image ? (
                        <img
                          src={
                            img?.image?.url ||
                            "https://images.unsplash.com/photo-1670092498973-3111f63d71fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1947&q=80"
                          }
                          alt=""
                          className="w-full object-contain rounded-md"
                        />
                      ) : (
                        <div className="col-span-1 cursor-pointer empty-slot h-[20vh] rounded-2xl border border-primary-gray-350 border-dashed flex flex-row items-center justify-center w-full mx-auto task-board-card-v2">
                          <div className="flex flex-row items-center justify-center rounded-full bg-primary-gray-60">
                            <Plus size={20} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewDetails;
