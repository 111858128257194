import Banner from "components/CGC/Banner";
import Experience from "components/CGC/Experience";
import FAQ from "components/CGC/FAQ";
import FindCGC from "components/CGC/FindCGC";
import Paths from "components/CGC/Paths";
import Scholarship from "components/CGC/Scholarship";
import What from "components/CGC/What";
import WorkExperience from "components/CGC/WorkExperience";
import React from "react";

function CGCFYI() {
  return (
    <>
      <Banner />
      <What />
      <WorkExperience />
      <Scholarship />
      {/*   <Paths />
        <Experience />
        <FAQ />
        <FindCGC /> */}
    </>
  );
}

export default CGCFYI;
