import React from "react";

function Banner() {
  return (
    <div className="self-stretch w-full min-h-screen px-2 pt-8 pb-2 bg-primary-red-500 justify-center items-center gap-2 inline-flex">
      <div className="flex-col justify-start items-center gap-10 inline-flex">
        <div className="flex-col justify-start items-center gap-10 flex">
          <div className="flex-col justify-start items-center flex">
            <div className="text-white text-[96px] lg:text-[148px] font-semibold font-poppins leading-[96px] lg:leading-[148px] tracking-[4.8px] lg:tracking-[7.40px]">
              CGC
            </div>
            <div className="text-white text-lg lg:text-2xl font-semibold font-poppins leading-5 lg:leading-[28.80px] tracking-[0.9px] lg:tracking-wide">
              Caarya Growth Centers
            </div>
          </div>
          <div className="self-stretch h-1 px-14 flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch h-1 bg-white rounded-[72px]" />
          </div>
        </div>
        <div className="text-center text-white text-[32px] lg:text-5xl font-semibold font-poppins leading-[48px] lg:leading-[72px] tracking-[0.8px] lg:tracking-wide">
          Your Path to Success
        </div>
      </div>
    </div>
  );
}

export default Banner;
