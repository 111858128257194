// The commented out routes are not being used for now and the associated components are marked as Deprecated ( These are not to be deleted )
import React from "react";
import { Redirect } from "react-router-dom";
import { checkAppAccess } from "helpers/utils/common/accessCheck";
import Resources from "pages/Resources";
import StoryDeck from "pages/StoryDeck";
import DeckPage from "pages/StoryDeck/DeckPage";

// Components

// Authenticated Paths

// Array of routes only a logged in user can access
// The menuTab key should have the value for the tab that needs to be highlighted when the user is on that page
const privateRoutes = [
  {
    path: "/storyDeck/:id",
    component: DeckPage,
  },

  {
    path: "/resources",
    component: Resources,
  },
  {
    path: "/storyDeck",
    component: StoryDeck,
  },

  {
    path: "/",
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          <Redirect to="/resources" />
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/" />
      ),
  },
];

export { privateRoutes };
