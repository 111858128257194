import DesktopWrapper from "components/Layout/DesktopWrapper";
import TabBar from "components/Layout/TabBar";
import TopBar from "components/Layout/TopBar";
import React from "react";

function AppWrapper({ children }) {
  return (
    <div className="h-screen w-screen overflow-hidden">
      <TopBar />
      <div className="max-h-[85vh] mt-11 py-3 lg:hidden overflow-y-auto">
        {children}
      </div>
      <DesktopWrapper>
        <div className="mt-0 py-0">{children}</div>
      </DesktopWrapper>
      <TabBar />
    </div>
  );
}

export default AppWrapper;
