import React, { useEffect, useState } from "react";
import { getActiveUsers } from "config/apis/user";

function MoodBoard() {
  const [imgs, setImgs] = useState([]);
  const [showText, setShowText] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        setFetching(true);

        const response = await getActiveUsers();
        const data = response.data.data;

        const images = data.map((item) => item?.image && item?.image?.url);

        setImgs(images);

        console.log("Fetched images:", images);
      } catch (err) {
        console.log("Fetch Active user task error", err);
      }
      setFetching(false);
    };
    fetchActiveUsers();
  }, []);

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const startFadeIn = (elms) => {
    const className = "js-fade-in";

    shuffle(elms)?.forEach((el, index) => {
      const delay = index * 50;
      setTimeout(() => el.classList.add(className), delay);
    });
  };

  const restart = (elms) => {
    const className = "js-fade-in";
    shuffle(elms)?.forEach((el) => el.classList.remove(className));

    setTimeout(() => startFadeIn(elms), 500);
  };

  useEffect(() => {
    // Select all img elements under .hero-bg-imgs after render
    const imgElements = Array.from(
      document.querySelectorAll(".hero-bg-imgs img")
    );
    setImgs(imgElements);
  }, []);

  useEffect(() => {
    if (imgs?.length > 0) {
      setTimeout(() => {
        restart(imgs);
        setTimeout(() => {
          setShowText(true);
        }, 2000);
      }, 500);
    }
  }, [imgs]);

  return (
    <div className="bg-black">
      <div className="max-w-7xl mx-auto w-full py-10">
        <div className="hero relative">
          <div className="hero-bg-imgs">
            {imgs?.map((image, index) => (
              <div className="flex-wrap" key={index}>
                <img src={image} alt="" className="rounded-lg" />
              </div>
            ))}
          </div>

          <div className="py-20 absolute top-0 right-0 left-0 bottom-0 bg-opacity-40 rounded">
            <div className="max-w-7xl mx-auto w-full py-20 flex flex-col items-center space-y-20 h-full justify-center">
              <h1 className="font-poppins text-2xl text-white lg:text-[72px] leading-[72px] text-center">
                The Caarya Network
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoodBoard;
