import React, { useEffect, useState } from "react";
import Stories from "react-insta-stories";
import { ColorExtractor } from "react-color-extractor";
import { getDeck } from "config/apis/public";
import YodaLoader from "components/Common/Loader/YodaLoader";
import { useParams } from "react-router-dom";

function StoryDeck() {
  const { code } = useParams();
  const [colors, setColors] = useState(["white"]);
  const [storyEnded, setStoryEnded] = useState(0);
  const [slides, setSlides] = useState([]);
  const [details, setDetails] = useState();
  const [fetching, setFetching] = useState(true);
  const getColors = (colors) => {
    console.log(colors);
    setColors(colors);
  };

  const fetchDeck = async () => {
    setFetching(true);
    try {
      const response = await getDeck(code);
      let fetcheddetails = response.data.data;
      let images = [];
      fetcheddetails?.images?.map((image, index) => {
        images.push({
          content: ({ action, isPaused }) => {
            return (
              <div className="w-screen h-screen flex flex-row items-center justify-center">
                <img
                  src={image?.url}
                  alt=""
                  className="w-full object-contain"
                />
              </div>
            );
          },
        });
      });

      setSlides(images);
      setDetails(fetcheddetails);
    } catch (err) {
      console.log("Fetch details error", err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchDeck();
    console.log(code);
  }, [code]);

  return (
    <div
      style={{ backgroundColor: `${colors[colors?.length - 1]}` }}
      className="w-screen h-screen insta-stories-wrapper"
    >
      {console.log("kok")}{" "}
      {fetching ? (
        <div className="h-full w-full flex flex-row items-center justify-center">
          <YodaLoader text="Redirecting you to a master piece ✨" />
        </div>
      ) : (
        <>
          <ColorExtractor getColors={getColors}>
            <img
              src={details?.images[storyEnded + 1]?.url}
              alt=""
              className="hidden h-0 w-0"
            />
          </ColorExtractor>

          <Stories
            stories={slides}
            loop
            keyboardNavigation
            defaultInterval={4000}
            onStoryEnd={(s, st) => {
              setStoryEnded(s == slides?.length - 1 ? 0 : s + 1);
              console.log("story ended", s, st);
            }}
            onAllStoriesEnd={(s, st) => console.log("all stories ended", s, st)}
            onStoryStart={(s, st) => console.log("story started", s, st)}
            storyContainerStyles={{
              overflow: "hidden",
              backgroundColor: "white",
            }}
          />
        </>
      )}
    </div>
  );
}

export default StoryDeck;
