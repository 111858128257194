import { useEffect, useState } from "react";

import { PlusIcon } from "@heroicons/react/outline";

import { useDispatch } from "react-redux";

import DecksModal from "components/StoryDeck/Modals/DecksModal";
import { deleteCollection, getAllCollections } from "config/apis/collection";
import { getAllLibrary } from "config/apis/library";
import WideModalsWrapper from "components/Common/Modals/ModalsWrapper/WideModalWrapper";
import EmptyState from "components/Common/EmptyState";
import ConfirmModal from "components/Common/Modals/ConfirmModal";
import { showToast } from "redux/toaster";
import { Pencil, Target } from "@phosphor-icons/react";

function CollectionList({ setSelectedCollection, selectedCollection }) {
  const dispatch = useDispatch();

  const [newModal, setNewModal] = useState(false);
  const [newModalLibrary, setNewModalLibrary] = useState(false);
  const [collection, setList] = useState();
  const [library, setLibrary] = useState();
  const [fetching, setFetching] = useState(true);
  const [selectedToEdit, setSelectedToEdit] = useState();
  const [selectedToDelete, setSelectedToDelete] = useState();
  const [query, setQuery] = useState({});

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchCollection();
    }

    return () => {
      isMounted = false;
    };
  }, [query]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchLibrary();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchCollection = async () => {
    try {
      let q = { ...query };

      const response = await getAllCollections(q);
      let newcollection = response.data.data || [];
      setList(newcollection);
      setFetching(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLibrary = async () => {
    try {
      let q = { ...query };

      const response = await getAllLibrary(q);
      let newcollection = response.data.data || [];
      setLibrary(newcollection);
      setFetching(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCollection(selectedToDelete.id);
      showSuccessNotification("Collection deleted successfully!");
      fetchCollection();
      setSelectedToDelete(null);
    } catch (err) {
      console.log("Library delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };

  return (
    <>
      <ConfirmModal
        isOpen={selectedToDelete ? true : false}
        closeModal={() => setSelectedToDelete(null)}
        onAccept={() => handleDelete()}
        text={
          <>
            Are you sure you want to delete the Collection:{" "}
            {selectedToDelete?.title}?
          </>
        }
      />

      <WideModalsWrapper
        component={
          <DecksModal
            type="collection"
            isOpen={selectedToEdit ? true : false}
            closeModal={() => setSelectedToEdit(false)}
            onCreate={() => fetchCollection()}
            onUpdate={() => fetchCollection()}
            editValues={selectedToEdit}
            isEdit={true}
            onDelete={(val) => {
              setSelectedToDelete(val);
            }}
          />
        }
        height="100vh"
        isOpen={selectedToEdit ? true : false}
        closeModal={() => setSelectedToEdit(false)}
      />
      <WideModalsWrapper
        component={
          <DecksModal
            type="collection"
            isOpen={newModal}
            libraryId={query?.libraryId}
            closeModal={() => setNewModal(false)}
            onCreate={() => fetchCollection()}
            onUpdate={() => fetchCollection()}
          />
        }
        height="100vh"
        isOpen={newModal}
        closeModal={() => setNewModal(false)}
      />

      <WideModalsWrapper
        component={
          <DecksModal
            type="library"
            isOpen={newModalLibrary}
            closeModal={() => setNewModalLibrary(false)}
            onCreate={() => fetchLibrary()}
            onUpdate={() => fetchLibrary()}
          />
        }
        height="100vh"
        isOpen={newModalLibrary}
        closeModal={() => setNewModalLibrary(false)}
      />

      <div className="bg-white border-[10px] border-primary-lavender-darkest rounded-3xl sm:max-w-2xl mx-auto pt-6 pb-6 relative">
        <div className="flex flex-row items-center justify-between space-x-2 px-5">
          <div className="flex flex-row items-center justify-start space-x-3">
            <h1 className="font-lato text-base text-primary-gray-600 font-bold">
              Collection
            </h1>

            <div className="flex flex-row items-center space-x-2">
              <PlusIcon
                className="w-4 h-4 cursor-pointer text-primary-indigo-300"
                onClick={() => {
                  setNewModal(true);
                }}
              />
            </div>
          </div>
          <div className="mr-1 theme-input px-4 py-2 font-lato text-sm font-normal text-primary-gray-600">
            <select
              className="bg-transparent w-36"
              onChange={(e) => {
                if (
                  e.target.value &&
                  e.target.value !== "All" &&
                  e.target.value !== "Add new"
                ) {
                  setQuery({ ...query, libraryId: e.target.value });
                }
                if (e.target.value && e.target.value == "Add new") {
                  setNewModalLibrary(true);
                }
              }}
            >
              <option id={99} key={99}>
                All
              </option>
              {library?.map((value) => (
                <option id={value?.id} key={value?.id} value={value?.id}>
                  {value?.title}
                </option>
              ))}
              <option id={9} key={9}>
                Add new
              </option>
            </select>
          </div>
        </div>
        <div className="min-h-25vh max-h-30vh md:max-h-60vh overflow-y-auto mt-2">
          <div className="px-5 flex flex-col">
            {(fetching || !collection) &&
              [1, 2, 3, 4]?.map((goal, index) => {
                return (
                  <>
                    <div
                      className={` flex flex-row items-center justify-between my-2 animate-pulse w-full`}
                    >
                      <div className="flex flex-row items-center relative w-full">
                        <img
                          className="h-6 w-6"
                          src={"/assets/images/icons/goal.svg"}
                          alt=""
                        />

                        <div
                          className={`leading-4 font-lato bg-indigo-100 rounded-lg w-10/12 h-4 text-sm cursor-pointer py-2 ml-3`}
                        >
                          <h3></h3>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            {!fetching && collection?.length > 0 ? (
              collection?.map((l, index) => {
                return (
                  <>
                    <div
                      className={`${
                        selectedCollection?.id === l.id &&
                        "bg-primary-lavender-lightest rounded-lg"
                      } ${
                        selectedCollection?.id === l.id
                          ? "font-bold text-primary-lavender-medium"
                          : "font-normal text-primary-gray-600"
                      } flex flex-row items-center justify-between px-2 my-2`}
                    >
                      <div
                        onClick={() => {
                          setSelectedCollection(l);
                        }}
                        className={` flex flex-row items-center relative`}
                      >
                        <Target size={26} />
                        <div
                          className={`leading-4 font-lato text-sm cursor-pointer w-full py-2 ml-3`}
                        >
                          <h3 className="pr-6">{l?.title}</h3>
                        </div>
                      </div>

                      <Pencil
                        onClick={() => {
                          setSelectedToEdit(l);
                        }}
                        className="h-3 w-3 mb-0.5 transform transition ease-in-out duration-150 hover:scale-105 cursor-pointer"
                        size={16}
                      />
                    </div>
                  </>
                );
              })
            ) : (
              <EmptyState text="You don't have any collections!" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectionList;
