import MoodBoard from "components/Landing/MoodBoard";
import React from "react";

function Landing() {
  return (
    <div>
      <MoodBoard />
    </div>
  );
}

export default Landing;
