import { fyiInstance, getHeader } from "..";

export const createLibrary = (body) =>
  fyiInstance.post(`/library`, body, getHeader());

export const updateLibrary = (id, body) =>
  fyiInstance.patch(`/library/${id}`, body, getHeader());

export const getLibrary = (LibraryId) =>
  fyiInstance.get(`/library/${LibraryId}`, getHeader());

export const getAllLibrary = (query = {}) =>
  fyiInstance.get(
    `/library${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

export const deleteLibrary = (LibraryId) =>
  fyiInstance.delete(`/library/${LibraryId}`, getHeader());
