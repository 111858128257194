import StoryPreview from "./index";

function StoryPreviewModal({ closeModal, setShowPreview, details }) {
  return (
    <div className="bg-white relative">
      <StoryPreview
        details={details}
        setShowPreview={(val) => {
          console.log("LLLLLLLL");
          setShowPreview(false);
        }}
      />
    </div>
  );
}

export default StoryPreviewModal;
